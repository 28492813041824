.frame {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .frame .div {
    background-color: #ffffff;
    border: 1px none;
    height: 1378px;
    position: relative;
    width: 2242px;
  }
  
  .frame .text-wrapper {
    color: #000000;
    font-family: "Optimistic Disp-Bd", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 1066px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 142px;
    white-space: nowrap;
  }
  
  .frame .p {
    color: #000000;
    font-family: "Optimistic Disp-Md", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 674px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    text-align: center;
    top: 217px;
    width: 893px;
  }
  
  .frame .ayy-click-here-to {
    color: #000000;
    font-family: "Optimistic Disp-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 1014px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    text-align: center;
    top: 338px;
    white-space: nowrap;
  }
  
  .frame .span {
    color: #000000;
    font-family: "Optimistic Disp-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 35px;
  }
  
  .frame .text-wrapper-2 {
    text-decoration: underline;
  }
  
  .frame .text-wrapper-3 {
    color: #000000;
    font-family: "Optimistic Disp-Md", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 1050px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    text-align: center;
    top: 438px;
    white-space: nowrap;
  }
  
  .frame .text-wrapper-4 {
    color: #000000;
    font-family: "Optimistic Disp-Md", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 1095px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    text-align: center;
    top: 1198px;
    white-space: nowrap;
  }
  
  .frame .overlap {
    background-color: #000000;
    border-radius: 100px;
    height: 79px;
    left: 610px;
    position: absolute;
    top: 682px;
    width: 466px;
  }
  
  .frame .text-wrapper-5 {
    color: #ffffff;
    font-family: "Optimistic Disp-Md", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 157px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    text-align: center;
    top: 21px;
    white-space: nowrap;
  }
  
  .frame .overlap-group {
    background-color: #000000;
    border-radius: 100px;
    height: 79px;
    left: 610px;
    position: absolute;
    top: 806px;
    width: 466px;
  }
  
  .frame .div-wrapper {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #000000;
    border-radius: 100px;
    height: 79px;
    left: 1185px;
    position: absolute;
    top: 682px;
    width: 466px;
  }
  
  .frame .text-wrapper-6 {
    color: #000000;
    font-family: "Optimistic Disp-Md", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 148px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    text-align: center;
    top: 20px;
    white-space: nowrap;
  }
  
  .frame .overlap-2 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #000000;
    border-radius: 100px;
    height: 79px;
    left: 1185px;
    position: absolute;
    top: 806px;
    width: 466px;
  }
  
  .frame .overlap-3 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #000000;
    border-radius: 100px;
    height: 79px;
    left: 1185px;
    position: absolute;
    top: 930px;
    width: 466px;
  }
  
  .frame .text-wrapper-7 {
    color: #000000;
    font-family: "Optimistic Disp-Bd", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 803px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    text-align: center;
    top: 602px;
    white-space: nowrap;
  }
  
  .frame .text-wrapper-8 {
    color: #000000;
    font-family: "Optimistic Disp-Bd", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 1365px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    text-align: center;
    top: 602px;
    white-space: nowrap;
  }
  
  .frame .vector-wrapper {
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(244.37, 244.37, 244.37) 100%);
    border: 0.5px solid;
    border-color: #e6e6e6;
    border-radius: 20.5px;
    box-shadow: 0px 0px 4px #0000001a, 0px 0px 1px #0000001a;
    height: 41px;
    left: 989px;
    position: absolute;
    top: 439px;
    width: 41px;
  }
  
  .frame .vector {
    height: 10px;
    left: 11px;
    position: absolute;
    top: 15px;
    width: 18px;
  }
  
  .frame .img-wrapper {
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(244.37, 244.37, 244.37) 100%);
    border: 0.5px solid;
    border-color: #e6e6e6;
    border-radius: 20.5px;
    box-shadow: 0px 0px 4px #0000001a, 0px 0px 1px #0000001a;
    height: 41px;
    left: 1211px;
    position: absolute;
    top: 439px;
    width: 41px;
  }
  
  .frame .img {
    height: 10px;
    left: 11px;
    position: absolute;
    top: 16px;
    width: 18px;
  }